<div *ngIf="model.length > 0" class="header-menu">
  <div class="option" *ngFor="let option of model">
      <div [ngClass]="{'option-header': true, 'active': currentOption && option.title === currentOption.title }"
           (click)="disabled ? false :toggleMenu($event, option)"
           *ngIf="allowedToShow(option)"
      >
          <a [routerLink]="option.href" *ngIf="option.href" (click)="disabled ? false : hideBurger()"
             [class.disabled]="disabled"
          >
          <img class="active-icon" src="../../../../assets/images/icons/{{option.icon}}-active.svg" alt="icon">
          <img class="default-icon" src="../../../../assets/images/icons/{{option.icon}}.svg" alt="icon">
          {{ option.title | translate }}
          </a>
          <span *ngIf="!option.href">{{ option.title | translate }}</span>
          <fa-icon [icon]="faChevronDown" *ngIf="option.children.length && (!openedOption || openedOption.title !== option.title)"></fa-icon>
          <fa-icon [icon]="faChevronUp" *ngIf="option.children.length && openedOption && openedOption.title === option.title"></fa-icon>
      </div>
      <ul class="submenu" *ngIf="option.children.length && openedOption && openedOption.title === option.title">
          <li *ngFor="let suboption of optionsFilteredByRole(option.children)"
              [ngClass]="{'suboption': true, 'active': currentSuboption && currentSuboption.title === suboption.title}"
          >
              <a [routerLink]="suboption.href" (click)="hideBurger()" *ngIf="suboption.href">{{ suboption.title | translate }}</a>
              <span *ngIf="!suboption.href">{{ suboption.title | translate }}</span>
          </li>
      </ul>
  </div>
</div>
