import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  current = 0;
  mobile = false;
  visiblePages: number[];

  @Input() total: number;

  @Output() changed: EventEmitter<number> = new EventEmitter();
  offset = 4;

  get pages(): number[] {
    return !this.total
      ? []
      : Array.from({ length: Math.ceil(this.total / 12) }).map(
        (item: any, i: number) => i,
      );
  }

  get last(): number {
    return Math.ceil(this.total / 12) - 1;
  }



  updateVisiblePages() {
    const totalPages = Math.ceil(this.total / 12);
    const maxVisiblePages = 4;

    let startPage = Math.max(this.current - Math.floor(maxVisiblePages / 2), 0);
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages - 1);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 0);
    }


    this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  }

  switchPage($event: Event, page: number): void {
    $event.preventDefault();

    this.current = page;
    this.changed.emit(this.current);
    if (this.current !== this.visiblePages[1]) {
      this.updateVisiblePages();
    }
  }

  previousPage($event: Event): void {
    $event.preventDefault();

    if (this.current !== 0) {
      this.current = this.current - 1;
      this.changed.emit(this.current);
      this.updateVisiblePages();
    }
  }

  firstPage($event: Event): void {
    $event.preventDefault();
    this.current = 0;
    this.changed.emit(this.current);
    this.updateVisiblePages();
  }

  nextPage($event: Event): void {
    $event.preventDefault();
    this.updateVisiblePages();
    if (this.current < Math.ceil(this.total / 12) - 1) {
      this.current = this.current + 1;
      this.changed.emit(this.current);
    }
  }

  lastPage($event: Event): void {
    $event.preventDefault();

    if (this.current < Math.ceil(this.total / 12) - 1) {
      this.current = this.pages.length - 1;
      this.changed.emit(this.current);
    }
    this.updateVisiblePages();
  }

  avgLeft() {
    this.current = Math.ceil(this.current / 2);
    this.changed.emit(this.current);
  }

  avgRight() {
    this.current = Math.ceil((this.pages.length - 1 + this.current) / 2);
    this.changed.emit(this.current);
  }



  ngOnInit() {
    if (window.screen.width <= 768) {
      this.mobile = true;
    }
    this.updateVisiblePages();
  }
}
