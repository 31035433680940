import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './components/loader/loader.component';
import { CustomSelectComponent } from './components/customSelect/customSelect.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserMenuComponent } from './components/userMenu/userMenu.component';
import { ValidationErrorComponent } from './components/ValidationError/validationError.component';
import { CookiesPolicyComponent } from './components/coockiesPolicy/cookiesPolicy.component';
import { CookiesPopupComponent } from './components/coockiesPolicy/cookiesPopup/cookiesPopup.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MessageComponent } from './components/message/message.component';
import { QuillModule } from 'ngx-quill';
import { GdprPopupComponent } from './components/gdprPopup/gdprPopup.component';
import { WideImgComponent } from './components/wideImg/wideImg.component';
import { SafePipe } from './pipes/safe.pipe';
import { UnsavedAlertComponent } from './components/unsavedAlert/unsavedAlert.component';
import { OfflineGuard } from './guards/offline.guard';
import { HeaderMenuComponent } from './components/headerMenu/headerMenu.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { InputComponent } from './components/input/input.component';
import { DropdownMenuComponent } from './components/dropdownMenu/dropdown-menu.component';
import { CustomSelect2Component } from './components/customSelect2/customSelect2.component';
import { ProfileGuard } from './guards/profile.guard';
import { RequestService } from './services/request.service';
import { StoreService } from './services/store.service';
import { CommonService } from './services/common.service';
import { GlobalErrorhandlerService } from './services/globalErrorhandler.service';
import { SearchService } from './services/search.service';
import { PersistenceService } from './services/persistence.service';
import { WideImgService } from '../modules/notes/utils/services/wideImg.service';
import { TokenService } from './services/token.service';
import { PreviewCardComponent } from './components/previewCard/previewCard.component';
import { ProfileService } from './services/profile.service';
import { TextareaComponent } from './components/textarea/textarea.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { RichtextEditorComponent } from './components/richtextEditor/richtextEditor.component';
import { DropDownMenuService } from './services/dropdown-menu.service';
import { TemplatePreviewCardComponent } from './components/templatesPreviewCard/templatePreviewCard.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { DatePicker } from './components/datePicker/datePicker.component';

@NgModule({
  imports: [
    HttpClientModule,
    FontAwesomeModule,
    TranslateModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    CKEditorModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
  ],
  providers: [
    RequestService,
    StoreService,
    CommonService,
    GlobalErrorhandlerService,
    SearchService,
    OfflineGuard,
    PersistenceService,
    ProfileGuard,
    WideImgService,
    TokenService,
    ProfileService,
    DropDownMenuService,
  ],
  declarations: [
    PreviewCardComponent,
    TemplatePreviewCardComponent,
    HeaderComponent,
    LoaderComponent,
    CustomSelectComponent,
    UserMenuComponent,
    ValidationErrorComponent,
    CookiesPolicyComponent,
    CookiesPopupComponent,
    PaginationComponent,
    MessageComponent,
    GdprPopupComponent,
    WideImgComponent,
    SafePipe,
    UnsavedAlertComponent,
    HeaderMenuComponent,
    MultiselectComponent,
    CheckboxComponent,
    InputComponent,
    DropdownMenuComponent,
    CustomSelect2Component,
    TextareaComponent,
    RichtextEditorComponent,
    DatePicker,
  ],
  exports: [
    FontAwesomeModule,
    HeaderComponent,
    LoaderComponent,
    CustomSelectComponent,
    UserMenuComponent,
    ValidationErrorComponent,
    CookiesPopupComponent,
    CookiesPolicyComponent,
    PaginationComponent,
    MessageComponent,
    GdprPopupComponent,
    WideImgComponent,
    SafePipe,
    CommonModule,
    UnsavedAlertComponent,
    HeaderMenuComponent,
    CheckboxComponent,
    InputComponent,
    CustomSelect2Component,
    PreviewCardComponent,
    TemplatePreviewCardComponent,
    TextareaComponent,
    MultiselectComponent,
    RichtextEditorComponent,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    DatePicker,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CoreModule {}
