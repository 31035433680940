import * as ProfileActions from '../actions/profileActions';
import { createReducer, on } from '@ngrx/store';

export const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  country: null,
  dealer: null,
  preferedLanguage: null,
  photo: '',
  userId: '',
  role: '',
  isGDPRAccepted: false,
  id: -1,
  created: null,
  modified: null,
};

export const profileReducer = createReducer(
  initialState,
  on(ProfileActions.store_user_profile_action, (state, { user }) => ({
    ...state,
    ...user,
  })),
);
