import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from '../interfaces/message';

@Injectable()
export class NotificationService {
  newError = new Subject<Message>();
  $newError = this.newError.asObservable();

  getNotification(type: 'error' | 'warning' | 'info' | 'success', msg: string) {
    this.newError.next({ type, message: msg });
  }
}
