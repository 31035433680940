import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DropDownMenuService {
  newDropdownId$ = new Subject<string>();

  setId(id: string) {
    this.newDropdownId$.next(id);
  }
}
