<div class="background"></div>
<div class="notes-popup">
	<div class="notes-popup-up">
		<div class="cancel" (click)="confirm(false)">
		   	<fa-icon [icon]="faClose"></fa-icon>
		</div>
		  
		<div class="icon_content">
			<div class="icon">
				<img src="../../../../assets/images/alert.svg" alt="">
			</div>
			<div class="content">
			    <p>You have changes in Workbook.</p>
			    <p>Click Save.</p>
			</div>
		</div>
	</div>

	<div class="controls">
	    <div class="save" (click)="confirm(true)">
	      	<fa-icon [icon]="faSave"></fa-icon>
	      	<div>Save changes</div>
	    </div>
	</div>

</div>
