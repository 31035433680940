<nav aria-label="...">
  <ul class="pagination">
    <li [ngClass]="{'page-item': true, 'disabled': current == 0}">
      <a class="page-link" href="#" tabindex="-1" (click)="firstPage($event)">
        <img src="../../../../assets/images/icons/first.svg" alt="icon">
      </a>
    </li>

    <li [ngClass]="{'page-item': true, 'disabled': current == 0}">
      <a class="page-link" href="#" tabindex="-1" (click)="previousPage($event)">
        <img src="../../../../assets/images/icons/previous.svg" alt="icon">
      </a>
    </li>

    <li *ngFor="let page of visiblePages" [ngClass]="{'page-item': true, 'active': page == current}">
      <a class="page-link" href="#" (click)="switchPage($event, page)"> {{ page + 1 }} <span
          class="sr-only">(current)</span></a>
    </li>

    <li class="page-item">
      <a [ngClass]="{'page-link': true, 'disabled': current == last }" href="#" (click)="nextPage($event)">
        <img src="../../../../assets/images/icons/next.svg" alt="icon">
      </a>
    </li>

    <li class="page-item">
      <a [ngClass]="{'page-link': true, 'disabled': current == last }" href="#" (click)="lastPage($event)">
        <img src="../../../../assets/images/icons/last.svg" alt="icon">
      </a>
    </li>
  </ul>

  <!-- <ul *ngIf="mobile && pages.length > 5 || !mobile && pages.length > 10" class="pagination">
    <li [ngClass]="{'page-item': true, 'disabled': current == 0}">
      <a class="page-link" href="#" tabindex="-1" (click)="firstPage($event)">
        <img src="../../../../assets/images/icons/first.svg" alt="icon">
      </a>
    </li>

    <li [ngClass]="{'page-item': true, 'disabled': current == 0}">
      <a class="page-link" href="#" (click)="previousPage($event)">
        <img src="../../../../assets/images/icons/previous.svg" alt="icon">
      </a>
    </li>

    <li *ngIf="current >= 3" [ngClass]="{'page-item': true, 'active': current == 0}">
      <a class="page-link" href="#" (click)="switchPage($event, pages[0])"> {{ pages[0] + 1 }} <span
          class="sr-only">(current)</span></a>
    </li>

    <ng-template [ngIf]="current < 3">
      <li *ngFor="let page of pages; let i = index" [ngClass]="{'page-item': true, 'active': page == current}">
        <a *ngIf="i < 3" class="page-link" href="#" (click)="switchPage($event, page)"> {{ page + 1 }}
          <span class="sr-only">(current)</span></a>
      </li>
    </ng-template>

    <li *ngIf="current >= 3" class="page-item">
      <a class="page-link" (click)="avgLeft()">...<span class="sr-only"></span></a>
    </li>

    <li *ngIf="current <= last-3 && current >= 3" [ngClass]="{'page-item': true, 'active': true}">
      <a class="page-link" href="#" (click)="switchPage($event, pages[current])"> {{ pages[current] + 1 }}
        <span class="sr-only">(current)</span></a>
    </li>

    <li *ngIf="current <= last-3" class="page-item">
      <a class="page-link" (click)="avgRight()">...<span class="sr-only"></span></a>
    </li>

    <ng-template [ngIf]="current > last-3">
      <li *ngFor="let page of pages; let i = index" [ngClass]="{'page-item': true, 'active': page == current}">
        <a *ngIf="i > last-3" class="page-link" href="#" (click)="switchPage($event, page)"> {{ page + 1 }}
          <span class="sr-only">(current)</span></a>
      </li>
    </ng-template>

    <li *ngIf="current <= last-3" [ngClass]="{'page-item': true, 'active': current == last}">
      <a class="page-link" href="#" (click)="switchPage($event, last)"> {{ last + 1 }} <span
          class="sr-only">(current)</span></a>
    </li>

    <li class="page-item">
      <a [ngClass]="{'page-link': true, 'disabled': current == last }" href="#" (click)="nextPage($event)">
        <img src="../../../../assets/images/icons/next.svg" alt="icon">
      </a>
    </li>

    <li class="page-item">
      <a [ngClass]="{'page-link': true, 'disabled': current == last }" href="#" (click)="lastPage($event)">
        <img src="../../../../assets/images/icons/last.svg" alt="icon">
      </a>
    </li>
  </ul> -->
</nav>