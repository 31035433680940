import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiselectComponent),
    },
  ],
})
export class MultiselectComponent implements ControlValueAccessor {
  @Input() items: any[] = [];
  @Input() titleProp = '';
  @Input() valueProp = '';
  @Input() errorText = '';
  @Input() listType: string = 'dealers';
  @Input() placeholder = '';
  // @Output() changed: EventEmitter<number[]> = new EventEmitter();

  opened = false;
  value: any[] = [];
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.opened = false;
    }
  }

  constructor(private eRef: ElementRef) {}

  get allWorkbooksSelected(): boolean {
    return this.value?.length === this.items?.length;
  }

  get title(): string {
    if (!this.value.length) {
      return this.placeholder;
    }

    if (this.value?.length > 1) {
      return `${this.value?.length} ${this.listType} selected`;
    }

    return `${this.value[0] ? this.value[0][this.titleProp] : ''}`;
  }

  get allChecked(): boolean {
    return this.value?.length === this.items?.length;
  }

  get allLabel(): string {
    return `components.multiselect.all${this.listType}`;
  }

  get allTitle(): string {
    return `components.multiselect.all${this.listType}`;
  }

  onChange = (value: any[]) => {};

  writeValue(value: any) {
    this.value = value || [];
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {}

  clear() {
    this.value = [];
    this.onChange(this.value);
  }

  select(event: boolean, item: any) {
    if (event) {
      this.value.push(item);
    } else {
      this.value = this.value.filter(
        (i: any) => i[this.valueProp] !== item[this.valueProp],
      );
    }

    this.onChange(this.value);
  }

  selectAll(event: boolean) {
    this.value = event ? this.items : [];
    this.onChange(this.value);
  }

  getItemTitle(item: any): string {
    return item[this.titleProp];
  }

  isChecked(item: any): boolean {
    return !!this.value?.find((v: any) => v.id === item.id);
  }

  toggle(event: any) {
    event.stopImmediatePropagation();
    this.opened = !this.opened;
  }
}
