import { IMenuOption } from '../../const/options';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

export const userMenuOptions: IMenuOption[] = [
  {
    name: 'global.components.user_menu.logout',
    path: '/logout',
    icon: faPowerOff,
    role: null,
  },
];
