import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createSelector, State, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

@Injectable()
export class StoreService {
  constructor(private state: State<any>, private store: Store<any>) {}

  getData(reducer: string, field?: string): any {
    let value;
    if (!field) {
      this.store.pipe(take(1)).subscribe((state) => {
        value = state[reducer];
      });
    } else {
      this.store.pipe(take(1)).subscribe((state) => {
        value = state[reducer][field];
      });
    }

    return value;
  }

  getDataSubscription(reducer: string, field?: string): Observable<any> {
    if (!field) {
      return this.store.pipe(map((state) => state[reducer]));
    }

    return this.store.pipe(map((state) => state[reducer][field]));
  }
}
