<!-- <div class="user_menu" *ngIf="profile">
  <div class="name" (click)="togleMenu()">
    <img src="../../../../assets/images/account.svg" style="margin-right: 5px">
    <span>{{ profile.firstName + ' ' + profile.lastName }}</span>
    <fa-icon [icon]="faCaretDown" [ngClass]="{'rotate': isOpened}"></fa-icon>
  </div>
  <ul class="menu" *ngIf="isOpened">
    <li *ngFor="let option of options" (click)="processClick($event, option.path)">
      <fa-icon [icon]="option.icon"></fa-icon>
      <span>{{ option.name | translate }}</span>
    </li>
  </ul>
</div> -->
<div class="logout" (click)="processClick($event, '/logout')">
  <img src="../../../../assets/images/icons/logout.svg" alt="icon">
  Logout
</div>
