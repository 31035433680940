import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-field-error',
  templateUrl: './validationError.component.html',
  styleUrls: ['./validationError.component.scss'],
})
export class ValidationErrorComponent {
  @Input() formControl: FormControl;
  @Input() errorMessage: string;
  param: { value: string };

  get message(): string {
    let text;

    if (!this.formControl.errors && !this.errorMessage) {
      return;
    }

    if (this.errorMessage) {
      return this.errorMessage;
    }

    if (this.formControl.errors.required) {
      text = 'public.auth.validation_errors.required';
    } else if (this.formControl.hasError('minlength')) {
      this.param = { value: this.formControl.errors.minlength.requiredLength };
      text = 'public.auth.validation_errors.minLength';
    } else if (this.formControl.hasError('maxlength')) {
      this.param = { value: this.formControl.errors.maxlength.requiredLength };
      text = 'public.auth.validation_errors.maxLength';
    } else if (this.formControl.hasError('email')) {
      text = 'public.auth.validation_errors.email';
    } else if (this.formControl.hasError('mustMatch')) {
      text = 'public.auth.validation_errors.pass_match';
    } else if (this.formControl.hasError('difficult')) {
      text = 'public.auth.validation_errors.difficult';
    } else if (this.formControl.hasError('startDate')) {
      text = 'public.auth.validation_errors.startDate';
    } else if (this.formControl.hasError('endDate')) {
      text = 'public.auth.validation_errors.endDate';
    } else if (this.formControl.hasError('isNotUniqueTitle')) {
      text = 'followup.firstStep.isNotUniqueTitle';
    }

    return text;
  }
}
