import { Injectable } from '@angular/core';
import { ICountry } from '../interfaces/ICountry';
import { ILang } from '../interfaces/ILang';
import { IDealer } from '../interfaces/IDealer';
import { IInnerRegion } from '../interfaces/IInnerRegion';
import { createAction, props } from '@ngrx/store';

export const STORE_COUNTRIES = '[Common] Fetch countries';
export const STORE_LANGUAGES = '[Common] Fetch languages';
export const STORE_DEALERS = '[Common] Fetch dealers';
export const STORE_INNER_REGIONS = '[Common] Fetch inner regions';
export const STORE_CONNECTED = '[Common] indexDB connected';

export const store_countries_action = createAction(
  STORE_COUNTRIES,
  props<{ countries: ICountry[] }>(),
);
export const store_langs_action = createAction(
  STORE_LANGUAGES,
  props<{ langs: ILang[] }>(),
);
export const store_dealers_action = createAction(
  STORE_DEALERS,
  props<{ dealers: IDealer[] }>(),
);
export const store_inner_regions = createAction(
  STORE_INNER_REGIONS,
  props<{ inner_regions: IInnerRegion[] }>(),
);
export const store_idb_connected = createAction(
  STORE_CONNECTED,
  props<{ connected: boolean }>(),
);
