import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { AppComponent } from './app.component';
import { rootReducer } from './store';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { LoaderService } from './core/services/loader.service';
import { NotificationService } from './core/services/notification.service';
import { ConnectionService } from 'ng-connection-service';
import { PersistenceService } from './core/services/persistence.service';
import { commonReducer } from './core/reducers/common.reducer';
import { profileReducer } from './core/reducers/profile.reducer';
import { utilsReducer } from './core/reducers/utils.reducer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Required for AOT compilation
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    StoreModule.forRoot({ commonReducer, profileReducer, utilsReducer }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    LoaderService,
    NotificationService,
    ConnectionService,
    {
      provide: APP_INITIALIZER,

      useFactory: (persistenceService: PersistenceService) => () => {},
      //persistenceService.connect(),
      deps: [PersistenceService],
      multi: true,
    },
  ],
  exports: [CoreModule, BrowserAnimationsModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    const enhancers = [];
    // if (process.env.NODE_ENV === 'dev' && devTools.isEnabled()) {
    //  enhancers = [ ...enhancers, devTools.enhancer() ];
    // }
  }
}
