<div class="followup-multiselect">
  <div [ngClass]="{ 'title': true, 'error': !!errorText }" (click)="toggle($event)">
    <span *ngIf="!allWorkbooksSelected" [ngClass]="{ placeholder: !value.length }">{{ title }}</span>
    <span *ngIf="allWorkbooksSelected">{{ allTitle | translate }}</span>
    <fa-icon [icon]="faChevronDown" *ngIf="!opened" (click)="toggle($event)"></fa-icon>
    <fa-icon [icon]="faChevronUp" *ngIf="opened" (click)="toggle($event)"></fa-icon>
  </div>
  <p class="error" *ngIf="errorText">{{ errorText }}</p>
  <div class="items" *ngIf="opened">
    <p class="clear" (click)="clear()">{{ 'components.multiselect.clear' | translate }}</p>
    <ul>
      <li *ngFor="let item of items" class="item">
        <app-checkbox (changed)="select($event, item)" [isChecked]="isChecked(item)"></app-checkbox>
        <label>{{ getItemTitle(item) }}</label>
      </li>
    </ul>

    <div class="separator"></div>

    <p class="all item">
      <app-checkbox (changed)="selectAll($event)" id="all" [isChecked]="allChecked"></app-checkbox>
      <label for="all">{{ allLabel | translate }}</label>
    </p>
  </div>
</div>
