import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
  loaderStateSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  loaderState$ = this.loaderStateSource.asObservable();
  activeRequests = 0;

  showLoader(): void {
    if (this.activeRequests === 0) {
      this.loaderStateSource.next(true);
    }

    this.activeRequests = this.activeRequests + 1;
  }

  hideLoader(): void {
    if (this.activeRequests === 1) {
      this.loaderStateSource.next(false);
    }

    this.activeRequests = this.activeRequests - 1;
  }

  skipLoader(): void {
    if (this.activeRequests > 0) {
      this.activeRequests = 1;
      this.hideLoader();
    }
  }
}
