import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-select',
  templateUrl: './customSelect.component.html',
  styleUrls: ['./customSelect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomSelectComponent),
    },
  ],
})
export class CustomSelectComponent implements ControlValueAccessor {
  @Input() set data(value: any) {
    this._data = value;

    if (!this._currentOption) {
      this._currentOption = { id: null, name: 'N/A', code: '', location: null };
    }
  }
  @Input() set current(value: any) {
    if (value) {
      this._currentOption = value;
    }
  }

  get current(): any {
    return this._currentOption;
  }

  get data(): any {
    return this._data;
  }

  constructor(private _eref: ElementRef) {}
  isOpen = false;
  arrowDown = faSortDown;
  arrowUp = faSortUp;
  _currentOption: any;
  _data: any;

  @Input() height: number;
  @Input() maxItems: number;
  @Input() disabled = false;

  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  onClose($event) {
    this.close($event);
  }

  toggleDropdown($event) {
    if (this.disabled) {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  openDropdown($event) {
    $event.stopImmediatePropagation();
    this.isOpen = true;
  }

  closeDropdown($event) {
    // if ($event )$event.stopImmediatePropagation();
    this.isOpen = false;
  }

  close(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      // or some similar check
      this.closeDropdown(null);
    }
  }

  selectOption($event) {
    this._currentOption = $event;
    this.change.emit($event);
    this.isOpen = false;
    this.onChange($event.value);
  }

  onChange = (value: string) => {};
  onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this._currentOption = this.data.find(
      (opt) => opt.value === value || opt.id === value,
    );
  }
}
