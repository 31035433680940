import { Injectable } from '@angular/core';
import * as moment from 'moment';
import type { IToken } from '../interfaces/IToken';
import { Router } from '@angular/router';
import { RequestService } from './request.service';
import * as TokenAction from '../actions/token.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class TokenService {
  constructor(
    private router: Router,
    private request: RequestService,
    private store: Store,
  ) { }

  getToken() {
    if (this.router.url.includes('/shared')) {
      return undefined;
    }

    try {
      const token = JSON.parse(localStorage.getItem('token') || '');
      return token.accessToken;
    } catch (error) {
      this.router.navigate(['/']);
    }
  }

  setToken(token: IToken) {
    localStorage.setItem('token', JSON.stringify(token));
    console.log('token', token);
  }

  /**
   * Refresh token
   *
   */
  refresh() {
    const refresh_expiration = JSON.parse(
      localStorage.getItem('refresh_expire') || '0',
    );

    if (
      refresh_expiration &&
      moment(new Date(+refresh_expiration.expire)).isAfter(new Date())
    ) {
      const refreshToken = refresh_expiration.refresh;

      this.request
        .post('AUTH_REFRESH', { refreshToken })
        .subscribe((token: IToken) => {
          this.store.dispatch(TokenAction.store_user_token_action({ token }));
          localStorage.setItem('token', JSON.stringify(token));
          localStorage.setItem(
            'refresh_expire',
            JSON.stringify({
              expire: (
                new Date().getTime() +
                token.expiresIn * 1000 +
                3600 * 1000
              ).toString(10),
              refresh: token.refresh_token,
            }),
          );
          document.location.reload();
        });
    } else {
      localStorage.removeItem('token');
      this.router.navigate(['/auth/login']);
    }
  }

  /**
   * Clear user credentials
   *
   */
  clearUserCreds() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_expire');
  }
}
