import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { of } from 'rxjs';
import { RequestService } from '../../core/services/request.service';
import type { INote } from '../../core/interfaces/INote';
import type { INoteShort } from '../../core/interfaces/INoteShort';
import { StoreService } from '../../core/services/store.service';
import { take } from 'rxjs/operators';
import { catchError, tap } from 'rxjs/operators';
import { NotificationService } from '../../core/services/notification.service';
import { TokenService } from '../../core/services/token.service';
import { BLOCK_TYPES } from '../../core/const/block-types';

@Injectable()
export class NotesService {
  constructor(
    private request: RequestService,
    private storeService: StoreService,
    private notificationService: NotificationService,
    private tokenService: TokenService,
  ) { }

  focusLost$ = new Subject<void>()
  $focusLost = this.focusLost$.asObservable()

  getFocusLost() {
    this.focusLost$.next()
  }

  getAllNotes(
    skip?: number,
    count?: number,
    search?: string,
    createDate?: string,
    sort?: string,
    state?: string,
    parentId?: string,
  ): Observable<{ total: number; items: INoteShort[] }> {
    let params: {
      skip?: number;
      count?: number;
      language: string;
      search?: string;
      createDate?: string;
      sort?: string;
      state?: string;
      parentId?: string;
    } =
      skip !== undefined && skip >= 0 && take
        ? { skip, count, language: 'en' }
        : { language: 'en' };

    if (search) {
      params = { ...params, search };
    }

    if (state) {
      params = { ...params, state };
    }

    if (parentId) {
      params = { ...params, parentId };
    }

    if (createDate) {
      params = { ...params, createDate };
    }

    if (sort) {
      params = { ...params, sort };
    }

    return this.request.get('NOTES_LIST', params, {
      Authorization: `Bearer ${this.tokenService.getToken()}`,
    });
  }

  createNote(): Observable<INote> {
    return this.request.post(
      'NOTES_CREATE',
      {},
      {
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      },
    );
  }

  saveNote(): Observable<any> {
    const note = { ...this.storeService.getData('notesReducer', 'current') };
    note.id = note.id || '00000000-0000-0000-0000-000000000000';
    note.contentBlocks = [...(note.contentBlocks || [])]
      .filter((block) => block.type >= 0)
      .filter((block) =>
        Object.keys(block).some(
          (key) =>
            key !== 'id' && key !== 'type' && key !== 'order' && !!block[key],
        ),
      );

    return this.request
      .post('NOTES_SAVE', note, {
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      })
      .pipe(
        take(1),
        catchError((error) => {
          this.notificationService.getNotification(
            'error',
            'Changes were not saved. Please try again',
          );
          throw error;
        }),
        tap((_) =>
          this.notificationService.getNotification(
            'success',
            'Changes were saved successfully.',
          ),
        ),
      );
  }

  getNote(id: string): Observable<INote> {
    return this.request.get(
      'NOTES',
      { id },
      {
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      },
    );
  }

  deleteNote(id: string): Observable<any> {
    return this.request
      .delete(
        'NOTES_DELETE',
        { id },
        { Authorization: `Bearer ${this.tokenService.getToken()}` },
      )
      .pipe(take(1));
  }

  duplicateNote(id: string) {
    return this.request
      .get(
        'NOTES_DUPLICATE',
        { id },
        { Authorization: `Bearer ${this.tokenService.getToken()}` },
      )
      .pipe(take(1));
  }

  addBlock(id: string) {
    return of({});
  }

  deleteBlockById(id: number | undefined) {
    return of({});
  }

  addNoteAttachment(
    file: File,
    blockType: BLOCK_TYPES,
  ): Observable<{ blob: string; shared: string; name: string }> {
    const sendedFile = new FormData();
    sendedFile.append('file', file);
    const headers = {};

    if (this.tokenService.getToken()) {
      headers['Authorization'] = `Bearer ${this.tokenService.getToken()}`;
    }

    if (blockType) {
      headers['Block-Type'] = blockType.toString();
    }
    return this.request
      .post('NOTES_UPLOAD_CONTENT', sendedFile, headers)
      .pipe(take(1));
  }

  publishNote(id: string): Observable<any> {
    return this.request.post(
      'NOTES_PUBLISH',
      { id },
      {
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      },
    );
  }

  shareNote(id: string): Observable<boolean> {
    return this.request.post(
      'NOTES_SHARE',
      { id },
      {
        Authorization: `Bearer ${this.tokenService.getToken()}`,
      },
    );
  }

  getSharedNote(id: string): Observable<INote> {
    return this.request.get('NOTES_SHARED', { id });
  }
}
