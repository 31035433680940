import { NetworkStates } from '../const/networkStates';
import * as UtilsActions from '../actions/utils.actions';
import { createReducer, on } from '@ngrx/store';

export interface UtilsState {
  networkState: NetworkStates;
}

export const initialState: UtilsState = {
  networkState: NetworkStates.ONLINE,
};

export const utilsReducer = createReducer(
  initialState,
  on(UtilsActions.setNetworkState, (state, { networkState }) => ({
    ...state,
    networkState,
  })),
);
