import type { ICountry } from '../interfaces/ICountry';
import type { ILang } from '../interfaces/ILang';
import type { IDealer } from '../interfaces/IDealer';
import type { IToken } from '../interfaces/IToken';

import * as CommonActions from '../actions/common.actions';
import * as TokenActions from '../actions/token.actions';
import { createReducer, on } from '@ngrx/store';

export const tokenInitState = {
  accessToken: '',
  refresh_token: '',
  expiresIn: 0,
  token_type: 'Bearer',
};

export interface CommonState {
  countries: ICountry[];
  langs: ILang[];
  dealers: IDealer[];
  inner_regions: string[];
  idb_connected: boolean;
  token: IToken;
}

const initialState = {
  countries: [],
  langs: [],
  dealers: [],
  inner_regions: [],
  idb_connected: false,
  token: tokenInitState,
};

export const commonReducer = createReducer(
  initialState,
  on(CommonActions.store_countries_action, (state, { countries }) => ({
    ...state,
    countries,
  })),
  on(CommonActions.store_dealers_action, (state, { dealers }) => ({
    ...state,
    dealers,
  })),
  on(CommonActions.store_langs_action, (state, { langs }) => ({
    ...state,
    langs,
  })),
  on(CommonActions.store_inner_regions, (state, { inner_regions }) => ({
    ...state,
    inner_regions,
  })),
  on(CommonActions.store_idb_connected, (state, { connected }) => ({
    ...state,
    idb_connected: true,
  })),
  on(TokenActions.store_user_token_action, (state, { token }) => ({
    ...state,
    token,
  })),
);
