import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SearchService {
  searchStateSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchState$ = this.searchStateSource.asObservable();

  toggleSearchBlock(state: boolean) {
    this.searchStateSource.next(state);
  }
}
