import { Component, ElementRef, HostListener, Input, OnInit, OnDestroy } from '@angular/core';
import { ICardMenuItem } from '../../interfaces/ICardMenuItem';
import { CardMenuItemTypeEnum } from '../../const/CardMenuItemType.enum';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { DropDownMenuService } from '../../services/dropdown-menu.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnInit, OnDestroy {
  @Input() items: any[] = [];
  @Input() customIcon = '';
  @Input() id = '';
  faEllipsisV = faEllipsisV;
  isOpen = false;
  destroyed$ = new Subject();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.ddMenu.setId('');
    }
  }

  constructor(private router: Router, private eRef: ElementRef, private ddMenu: DropDownMenuService) {}

  ngOnInit(): void {
    this.ddMenu.newDropdownId$
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged()
      )
      .subscribe(id => this.isOpen = id === this.id );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete()
  }

  toggleMenu(event) {
    event.stopImmediatePropagation();
    this.isOpen = !this.isOpen;
    this.ddMenu.setId(this.id);
  }

  handleMenuItemClick(event: any, item: ICardMenuItem): void {
    event.stopImmediatePropagation();

    this.isOpen = false;

    if (item.type === CardMenuItemTypeEnum.Link) {
      this.router.navigate([item.route]);
    }

    if (item.type === CardMenuItemTypeEnum.Action) {
      item.action();
    }
  }
}
