<div class="popup" *ngIf="isOpened">
  <div class="header">
    Cookies of Digital Learner Guide
    <fa-icon [icon]="faTimes" (click)="close()"></fa-icon>
  </div>
  <div class="body">
    <p>To improve the present and future user experience of this website (<a href="https://workbookify.com" target="_blank">https://workbookify.com</a>) cookies are used.</p>
    <p>A cookie is a small text file that a website saves on the computer or on the mobile device of the user that is browsing. Thanks to cookies the website remembers the actions and expressed preferences (such as authentication to restricted areas, the selected language or other display settings) so that the user does not have to renter them again when returning to the site or navigating between one page and another. Cookies are also used to check that visits are made by new or return visitors and store certain information, anonymous and non-personal, relating to the navigation of the pages (input and output link, page navigation, residence times, etc.).</p>
    <p>By using the website you agree to have installed on your device “owners” and “third party” cookies indicated in the “List of cookies used” below. Disabling the saving of all or some cookies, the site navigability (or some sections of it) could be affected or limited by a lack of functionality.</p>
    <h2>Types of cookies</h2>
    <p>Cookies differ depending on their duration, the domain that installed them and their function.</p>
    <h3>Duration</h3>
    <br/>
    <h3>Session cookies</h3>
    <p>These are temporary files that are stored until the site is left (or the web browser closed).</p>
    <h3>Persistent cookies</h3>
    <p>These are files that are stored and maintained even after having left the site and the browser closed. They are eliminated only after the expiry date specified in the cookie itself.</p>
    <h3>Domain</h3>
    <br/>
    <h3>Owners Cookies</h3>
    <p>These are files installed directly on your device by the site which you are browsing.</p>
    <h3>“Third-party” Cookies</h3>
    <p>These are files that are installed by external sites through codes integrated in the pages of the site you are browsing. Examples of third-party cookies are those installed by social plug-in (for content sharing) or visit analysis cookies.</p>
    <h3>Function</h3>
    <br/>
    <h3>Indispensable “Technical” Cookies</h3>
    <p>This site does NOT use cookies of this type. These are essential for the proper functioning of the site’s pages. Without these cookies some features could be compromised and content access restricted. They allow, for example, to keep track of authentications performed by a user or by a site administrator. Cookies of this type do not collect any of the user’s personal information.</p>
    <h3>Functional “Technical” Cookies</h3>
    <p>This site DOES USE cookies of this type (Google Analytics). These are cookies used to store user choices made to improve navigation (such as language, or pre-draft a form with the previously used name). These types of cookies may include contact information (such as your user name). Without these cookies, some features and page navigation may be affected.</p>
    <h3>Performance Cookies</h3>
    <p>This site does NOT use cookies of this type. These cookies are used to understand: if users are new or returning, how they use the site, how they move through the pages, how long they stay on each page and on the website, from which geographic area they arrived to the site. The data does not identify you as a person but is aggregated anonymously by the analysis tools. Deactivation does not compromise in any way the features offered by the site.</p>
    <h3>Social widget Cookies</h3>
    <p>This site does NOT use cookies of this type. Some widgets provided by social networks (e.g. Facebook, Twitter, YouTube, Google Maps, etc.) can use its third parties cookies. Deactivation does not alter site use, except in sections where widgets can be installed (e.g. for integration of video, or maps) and in some cases the possibility of rapid content sharing or the opportunity to comment on some areas of the site .</p>
    <h3>Advertising Cookies</h3>
    <p>This site does NOT use cookies of this type. Advertising cookies are installed to show visitors of the site content related to their preferences. They can then be used to display advertising content targeted at the person’s interests. Cookies of this type work in collaboration with third-party sites and can keep track of past browsing pages on different domains. Cookies of this type keep track usually of your IP address and other information, some of which may be personal.</p>
    <h3>Google Analytics</h3>
    <p>Our pages use third-party performance cookies of Google Analytics (a service offered by Google Inc.) to permit us to collect anonymously and examine the behavior of visitors during site use to improve its functioning and the user experience.By using the Google Analytics panel, we can see if visits were made by new or returning visitors, monitor how pages are browsed (input and output links, movements between pages, duration times, geographical origin, etc.).For more information on Google Analytics you can visit the websites:</p>
    <p>• <a href="https://marketingplatform.google.com/about/partners/" target="_blank">www.google.it/.../partners/</a></p>
    <p>• <a href="https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage" target="_blank">www.developers.google.com/.../cookie-usage</a></p>
    <p>It is possible to disable Google Analytics actions with the tools provided by Google. For information consult the site:</p>
    <p>• <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">www.tools.google.com/.../gaoptout</a></p>
    <h3>Managing and deleting cookies</h3>
    <p>To disable the use of all or some cookies you must change the settings of the navigation browser (Firefox, Chrome, Explorer, Safari, Opera, etc.).</p>
    <p>Typically browsers are pre-set to accept cookies, unless they are configured by the user in a different way. It is possible to make sure that the browser blocks some or all cookies, or else send a message to the user before proceeding to install a cookie.For your convenience these are the links to manage cookies for the most popular browsers:</p>
    <p>• <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">Chrome</a></p>
    <p>• <a href="https://support.mozilla.org/en-US/kb/exp/enable-and-disable-cookies-website-preferences" target="_blank">Firefox</a></p>
    <p>• <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Explorer</a></p>
    <p>• <a href="https://addons.opera.com/en/extensions/details/cookie-editor/" target="_blank">Opera</a></p>
    <p>• <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">Safari</a></p>
  </div>
</div>
