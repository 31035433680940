import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-gdpr-popup',
  templateUrl: './gdprPopup.component.html',
  styleUrls: ['./gdprPopup.component.scss'],
})
export class GdprPopupComponent {
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();

  faTimes = faTimes;

  close() {
    this.closePopup.emit(true);
  }
}
