<div class="header" (click)="hideWbNav()">
  <div class="title">
      <img src="../../../../assets/images/HARLEY-DAVIDSON_OUTLINE_RGB_white.svg" alt="" (click)="toHome()">
  </div>
  <div class="mobile-wrapper">
    <app-header-menu [disabled]="disabled"></app-header-menu>
    <div class="search">
        <!-- <div class="search_help_block">
            <a href="#" [routerLink]="'/shared/bot'" class="bot_btn" [class.disabled]="disabled">
                <img class="white" src="../../../../assets/images/record_voice_over-24px.svg" alt="">
                <img class="mob-img" src="../../../../assets/images/record_voice_over(mob).svg" alt="">
                <span>Bot</span>
            </a>
            <a class="search-btn" href="#" [ngClass]="{ 'searchOpened': searchState }"
               (click)="toggleSearch($event)" *ngIf="showSearch"
            >
                <img src="../../../../assets/images/round-search-24px.svg" alt="">
                <span>Search</span>
            </a>
            <a class="support"  href="mailto:help@bridgelt.com" [class.disabled]="disabled">
                <img src="../../../../assets/images/outline-help_outline-24px.svg" alt="">
                <span>Support</span>
            </a>
        </div> -->
        <app-user-menu></app-user-menu>
    </div>
  </div>
</div>
<app-cookies [isOpen]="!cookiesAccepted"></app-cookies>
