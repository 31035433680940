export const URLS = {
  LOGIN: 'Auth/login',
  LOGIN_SAML: 'auth/saml',
  TOKEN_SAML: 'auth/samlresponse',
  LOGIN_VERIFY: 'auth/loginverify',
  COUNTRIES: 'Dictionary/countries',
  LANGUAGES: 'Dictionary/languages',
  DEALERS: 'Dictionary/dealers',
  INNER_REGIONS: 'dictionary/innerregions',
  PROFILE: 'Account/profile',
  ACCOUNT_REGISTER: 'account/register',
  ACCOUNT_FORGOT: 'account/forgot',
  ACCOUNT_RESET: 'account/reset',
  REFRESH: 'Auth/refresh',
  AUTH_VERIFY: 'auth/verified',
  AUTH_REFRESH: 'auth/refresh',
  ATTACHMENT: 'attachment',
  ATTACHMENT_UPLOAD: 'attachment/upload',
  ATTACHMENT_ADD: 'attachment/add',
  ATTACHMENT_LIST: 'attachment/list',
  TEST_LIST: 'survey/assessments',
  NOTES: 'notes',
  NOTES_SAVE: 'notes/save',
  NOTES_LIST: 'notes/list',
  NOTES_UPLOAD_CONTENT: 'notes/upload',
  NOTES_PUBLISH: 'notes/publish',
  NOTES_DUPLICATE: 'notes/duplicate',
  NOTES_DELETE: 'notes/delete',
  NOTES_DELETE_ATTACHMENT: 'notes/deletefile',
  NOTES_SHARE: 'notes/share',
  NOTES_SHARED: 'sharednotes',
  CONTENT_UPLOAD: 'Content/Upload',
  TEMPLATES: 'templates',
  TEMPLATES_LIST: 'templates/list',
};
