import { Component, OnDestroy } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnDestroy {
  isVisible = false;
  loaderSubscription: Subscription;

  constructor(private loaderService: LoaderService) {
    this.loaderSubscription = this.loaderService.loaderState$
      .subscribe((state: boolean) => this.isVisible = state);
  }

  ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }
}
