import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import * as CommonActions from '../actions/common.actions';
import { Store } from '@ngrx/store';

const REQUESTS = 'REQUESTS';
export const REQUESTS_STORE_GET = 'REQUESTS_STORE_GET';
export const REQUESTS_STORE_POST = 'REQUESTS_STORE_POST';
export const REQUESTS_STORE_PUT = 'REQUESTS_STORE_PUT';
export const REQUESTS_STORE_DELETE = 'REQUESTS_STORE_DELETE';

@Injectable({
  providedIn: 'root',
})
export class PersistenceService {
  private RequestsDB;
  private db!: any;
  instance!: PersistenceService;

  constructor(private store: Store) {
    if (this.instance) {
      return this.instance;
    }

    this.RequestsDB = window.indexedDB.open(REQUESTS, 1);

    this.RequestsDB.onsuccess = (event: any) => {
      this.db = event.target?.result;

      this.store.dispatch(
        CommonActions.store_idb_connected({ connected: true }),
      );
    };

    this.RequestsDB.onupgradeneeded = (event: any) => {
      this.db = event.target?.result;
      this.db.createObjectStore(REQUESTS_STORE_GET, {
        keyPath: 'url',
        autoIncrement: false,
      });
      this.db.createObjectStore(REQUESTS_STORE_POST, {
        keyPath: 'url',
        autoIncrement: false,
      });
      this.db.createObjectStore(REQUESTS_STORE_PUT, {
        keyPath: 'url',
        autoIncrement: false,
      });
      this.db.createObjectStore(REQUESTS_STORE_DELETE, {
        keyPath: 'url',
        autoIncrement: false,
      });
    };
  }

  getAll(storeName: string): Observable<any> {
    const transaction = this.db.transaction([storeName], 'read');
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    return from(
      Promise.resolve(
        new Promise((resolve, reject) => {
          request.onsuccess = (event: any) => {
            resolve(request.result.name);
          };
        }),
      ),
    );
  }

  save(storeName: string, item: any): Observable<any> {
    if (!this.db) {
      return from(
        Promise.resolve(
          new Promise((resolve, reject) => {
            const to = setTimeout(() => {
              const t = this.db.transaction([storeName], 'readwrite');
              const s = t.objectStore(storeName);
              const r = s.put(item);

              r.onsuccess = (event: any) => {
                clearTimeout(to);
                resolve(r.result.name);
              };
            }, 200);
          }),
        ),
      );
    }

    const transaction = this.db.transaction([storeName], 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.put(item);

    return from(
      Promise.resolve(
        new Promise((resolve, reject) => {
          request.onsuccess = (event: any) => {
            resolve(request.result.name);
          };
        }),
      ),
    );
  }

  get(store: string, url: string): Promise<any> {
    if (!this.db) {
      return new Promise((resolve, reject) => {
        const to = setTimeout(() => {
          const t = this.db.transaction([store], 'readonly');
          const ts = t.objectStore(store);
          const r = ts.get(url);

          r.onsuccess = (event: any) => {
            clearTimeout(to);
            resolve(r.result);
          };
        }, 500);
      });
    }

    const transaction = this.db.transaction([store], 'readonly');
    const s = transaction.objectStore(store);
    const request = s.get(url);

    return new Promise((resolve, reject) => {
      request.onsuccess = (event: any) => {
        resolve(request.result);
      };
    });
  }
}
