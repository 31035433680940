import { Component, forwardRef, Input } from '@angular/core';
import type { ControlValueAccessor } from '@angular/forms';
import { FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'notes-date-picker',
  templateUrl: './datePicker.component.html',
  styleUrls: ['./datePicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePicker),
    },
  ],
})
export class DatePicker implements ControlValueAccessor {
  @Input() emptyText = 'From Start';
  @Input() format = 'YYYY-MM-DD';

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = fb.group({
      date: fb.control(''),
    });
  }

  get date() {
    return this.form.value.date;
  }

  writeValue(obj: any) {
    this.form.patchValue({ date: obj });
  }

  onPreChange(event: any) {
    console.log(event.value);
    this.onChange(moment(event.value).format(this.format));
  }

  onChange(value: any) {
    console.log('Changed: ', value);
  }
  onTouch() {
    console.log('Touched: ');
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
