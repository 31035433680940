import { Component } from '@angular/core';
import { userMenuOptions } from './options';
import { StoreService } from '../../services/store.service';
import { IProfile } from '../../interfaces/IProfile';
import { Subscription } from 'rxjs';
import {
  faUser,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './userMenu.component.html',
  styleUrls: ['./userMenu.component.scss'],
})
export class UserMenuComponent {
  faUser = faUser;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  options = userMenuOptions;
  profile: IProfile;
  isOpened = false;

  profileSubscroption: Subscription;

  constructor(
    private storeService: StoreService,
    private router: Router,
    private tokenService: TokenService,
  ) {
    this.profileSubscroption = this.storeService
      .getDataSubscription('profileReducer')
      .subscribe((profile: IProfile) => (this.profile = profile));
  }

  togleMenu() {
    this.isOpened = !this.isOpened;
  }

  processClick($event, path) {
    if (path === '/logout') {
      this.router.navigate(['/auth/samllogin']).then((_) => {
        this.tokenService.clearUserCreds();
      });
    }
  }
}
