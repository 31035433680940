<div class="custom_select">
  <div class="header" [ngStyle]="{'height': height + 'px'}" (click)="toggleDropdown($event)">
    <span [ngClass]="{ 'current': true, placeholder: !current || !current[titleOption] }">
      {{ current && current[titleOption] || placeholder }}
    </span>
    <fa-icon [icon]="arrowDown" *ngIf="!isOpen && !disabled" (click)="openDropdown($event)"></fa-icon>
    <fa-icon [icon]="arrowUp" (click)="closeDropdown($event)" *ngIf="isOpen && !disabled"></fa-icon>
  </div>
  <div class="options" *ngIf="isOpen && data" [ngStyle]="{'max-height': height*maxItems + 'px'}">
    <div class="option"
         *ngFor="let option of data"
         [ngStyle]="{'height': height + 'px', 'line-height': height + 'px'}"
         id="{{option.id || option.code}}"
         (click)="selectOption(option)"
    >
      {{ option.name || option.title }}
    </div>
  </div>
</div>
