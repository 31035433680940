import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { Roles } from '../../const/roles';

@Component({
  selector: 'app-template-preview-card',
  templateUrl: './templatePreviewCard.component.html',
  styleUrls: ['./templatePreviewCard.component.scss'],
})
export class TemplatePreviewCardComponent {
  faArrowDown = faArrowDown;
  Roles = Roles;

  @Input() item: any; // Short model of Training/Survey/Workbook
  @Input() status: any;
  @Input() menu: any[] = this.initMenu;
  @Input() role: Roles = Roles.USER;

  @Output() refreshList: EventEmitter<boolean> = new EventEmitter<boolean>();

  get menuIcon() {
    return '';
  }

  get initMenu(): any[] {
    return [];
  }

  get createdMenu(): any[] {
    return this.menu?.length ? this.menu : this.initMenu;
  }

  getCreatedDate(date: string): string {
    return moment(date).format('YYYY-MM-DD');
  }
}
