import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RequestService } from '../services/request.service';
import { Injectable } from '@angular/core';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class OfflineGuard implements CanActivate {

  constructor(private request: RequestService, private notifications: NotificationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (this.request.isOnline()) {
      return true;
    }

    const routes = localStorage.getItem('routes');
    this.notifications.getNotification('error', 'You under offline mode');

    return routes.indexOf(route.url.join('')) > 0;
  }
}
