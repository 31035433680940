<div [formGroup]="form" class="note-date-picker">
  <div class="input-wrapper">
    <input matInput
           [matDatepicker]="releasedAtPicker"
           formControlName="date"
           (dateChange)="onPreChange($event)"
    >
    <div class="pseudo-input" (click)="releasedAtPicker.open()"></div>
    <ng-container *ngIf="!date">
      <div class="empty-state">
        {{emptyText}}
      </div>
    </ng-container>
  </div>

  <mat-datepicker-toggle matSuffix [for]="releasedAtPicker" [disabled]="false"></mat-datepicker-toggle>

  <mat-datepicker #releasedAtPicker [disabled]="false"></mat-datepicker>
</div>
