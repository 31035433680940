import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { IMenuOption } from '../../interfaces/IMenuOption';
import { headerMenuModel } from './model';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './headerMenu.component.html',
  styleUrls: ['./headerMenu.component.scss'],
})
export class HeaderMenuComponent {
  constructor(
    private router: Router,
    private storeService: StoreService,
    private _eref: ElementRef,
  ) {}

  get currentOption(): IMenuOption {
    return this.model.filter((opt: IMenuOption) =>
      opt.href &&
      !this.model.some((o) =>
        o.children.some((ch) => ch.href === this.router.url),
      )
        ? this.router.url.includes(opt.href)
        : opt.children.some((ch: IMenuOption) => ch.href === this.router.url),
    )[0];
  }

  get currentSuboption(): IMenuOption {
    return this.model
      .map((o: IMenuOption) =>
        o.children.length > 0
          ? o.children.filter(
              (so: IMenuOption) => so.href === this.router.url,
            )[0]
          : null,
      )
      .filter((so) => so)[0];
  }
  model: IMenuOption[] = headerMenuModel;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  openedOption: IMenuOption;

  @Input() disabled: boolean;

  @HostListener('document:click', ['$event'])
  onClose(event) {
    this.close(event);
  }

  toggleMenu(event: any, option: IMenuOption) {
    event.stopPropagation();

    this.openedOption =
      this.openedOption && this.openedOption.title === option.title
        ? null
        : option;
  }

  optionsFilteredByRole(options) {
    return options.filter((o) => this.allowedToShow(o));
  }

  allowedToShow(option: IMenuOption): boolean {
    const role = this.storeService.getData('profileReducer').role;
    const country = this.storeService.getData('profileReducer').country;
    const countryCode = country ? country.isoCode : '';

    return option.allowed.indexOf(role) >= 0; // && option.disallowCountries.indexOf(countryCode) === -1;
  }

  close(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      // or some similar check
      this.openedOption = null;
    }
  }

  hideBurger() {
    document.querySelector('.mobile-wrapper').classList.remove('mobile-show');
  }
}
