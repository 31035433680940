import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TokenService } from '../services/token.service';
import { ProfileService } from '../services/profile.service';

@Injectable()
export class ProfileGuard implements CanActivate {
  constructor(
    private tokenService: TokenService,
    private profileService: ProfileService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.tokenService.getToken() && state.url.includes('/followup/view')) {
      localStorage.setItem('immediateRedirectUrl', state.url);
    }

    return new Promise((resolve, reject) => {
      this.profileService
        .fetchProfile()
        .pipe(take(1))
        .subscribe(
          () => resolve(true),
          () => resolve(false),
        );
    });
  }
}
