import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorhandlerService {
  authErrorSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  authError$ = this.authErrorSource.asObservable();

  constructor(private router: Router) {}

  processGlobalErrors(error: any): Observable<any> {
    if (error.status === 401) {
      this.authErrorSource.next(true);
      this.router.navigate(['/auth/login']);
    }

    return throwError(error);
  }
}
