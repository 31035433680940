<div class="card">
  <div class="card-header">
    <div class="card-date">
      {{ getCreatedDate(item.created) }}
    </div>
    <div class="card-status" [ngClass]="{'published': item.state === 'published'}">
      {{ item.state }}
      <img src="../../../../assets/images/icons/{{item.state !== 'published' ? 'draft' : 'published'}}.svg" alt="icon">
    </div>
    <div class="menu">
      <app-dropdown-menu *ngIf="createdMenu.length" [items]="createdMenu" [customIcon]="menuIcon" [id]="item.id"></app-dropdown-menu>
    </div>
  </div>
  <div class="card-title">
    {{ item.title }}
  </div>
  <div  class="card-description"
    [innerHTML]="item?.description">
  </div>
  <div  class="card-description createdBy" *ngIf="isManager">{{item?.createdBy?.firstName}} {{item?.createdBy?.lastName}}</div>
</div>
