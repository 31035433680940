import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cookies-popup',
  templateUrl: './cookiesPopup.component.html',
  styleUrls: ['./cookiesPopup.component.scss'],
})
export class CookiesPopupComponent {
  @Input() isOpened: boolean;
  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  faTimes = faTimes;

  close() {
    this.isOpened = false;
    this.closed.emit(false);
  }
}
