import { Roles } from '../../const/roles';
import { IMenuOption } from '../../interfaces/IMenuOption';

export const headerMenuModel: IMenuOption[] = [
  {
    title: 'global.head_menu.notes',
    allowed: [Roles.USER, Roles.NATIONAL_MANAGER, Roles.LOCAL_MANAGER, Roles.ADMIN],
    children: [],
    disallowCountries: [],
    href: '/notes',
    icon: 'notes',
  },
  // {
  //   title: 'global.head_menu.templates',
  //   allowed: [Roles.USER, Roles.NATIONAL_MANAGER, Roles.LOCAL_MANAGER, Roles.ADMIN],
  //   children: [],
  //   disallowCountries: [],
  //   href: '/templates',
  //   icon: 'templates',
  // },
  // {
  //   title: 'global.head_menu.tasks',
  //   allowed: [Roles.USER, Roles.NATIONAL_MANAGER, Roles.LOCAL_MANAGER, Roles.ADMIN],
  //   children: [],
  //   disallowCountries: [],
  //   href: '/tasks',
  //   icon: 'tasks',
  // },
];
