import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  value = '';
  @Input() type = 'text';
  @Input() errorMessage = '';
  @Input() control: FormControl;
  @Input() placeholder = '';

  get showError(): boolean {
    return (
      ((this.control.touched || this.control.dirty) &&
        (this.control.hasError('minlength') ||
          this.control.hasError('required') ||
          this.control.hasError('maxlength'))) ||
      !!this.errorMessage
    );
  }

  onChange = (value: string) => {};
  onTouched = () => {};

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  handleInput() {
    this.onChange(this.value);
  }
}
