import {
  Component,
  forwardRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  value = false;
  faCheck = faCheck;
  @Input()
  set isChecked(value: boolean) {
    this.value = value;
  }
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  onChange(_: any) {
    this.changed.emit(this.value);
  }

  handleChange(event: any) {
    this.changed.emit(this.value);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {}
}
