import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { from, Observable } from 'rxjs';
import { ICountry } from '../interfaces/ICountry';
import { ILang } from '../interfaces/ILang';
import { catchError, map } from 'rxjs/operators';
import { StoreService } from './store.service';
import * as CommonActions from '../actions/common.actions';
import { IDealer } from '../interfaces/IDealer';
import { IInnerRegion } from '../interfaces/IInnerRegion';
import { Store } from '@ngrx/store';

@Injectable()
export class CommonService {
  constructor(
    private request: RequestService,
    private storeService: StoreService,
    private store: Store,
  ) {}

  // Fetch countries
  fetchCounties(): Observable<ICountry[]> {
    return this.request.get('COUNTRIES').pipe(
      map((countries: ICountry[]): any =>
        this.store.dispatch(
          CommonActions.store_countries_action({ countries }),
        ),
      ),
      catchError((error: Error) => {
        console.log('FETCH COUNTRIES ERROR: ', error);
        return from([]);
      }),
    );
  }
  // Fetch dealers

  // Fetch languages
  fetchLangs(): Observable<ILang[]> {
    return this.request.get('LANGUAGES').pipe(
      map((langs: ILang[]): any =>
        this.store.dispatch(CommonActions.store_langs_action({ langs })),
      ),
      catchError((error: Error) => {
        console.log('FETCH LANGS ERROR: ', error);
        return from([]);
      }),
    );
  }

  // Fetch dealers
  fetchDealers(): Observable<IDealer[]> {
    return this.request.get('DEALERS').pipe(
      map((dealers: IDealer[]): any =>
        this.store.dispatch(
          CommonActions.store_dealers_action({
            dealers: [
              { id: -1, name: 'N/A', code: '', location: null } as IDealer,
            ].concat(
              dealers.sort((a: IDealer, b: IDealer) =>
                a.name <= b.name ? -1 : 1,
              ),
            ),
          }),
        ),
      ),
      catchError((error: Error) => {
        console.log('FETCH DEALERS ERROR: ', error);
        return from([]);
      }),
    );
  }

  // Fetch inner regions by country code
  fetchInnerRegions(countryCode: string): Observable<IInnerRegion[]> {
    return this.request.get('INNER_REGIONS', { countryCode }).pipe(
      map((regions: IInnerRegion[]): any =>
        this.store.dispatch(
          CommonActions.store_inner_regions({ inner_regions: regions }),
        ),
      ),
      catchError((error: Error) => {
        console.log('FETCH DEALERS ERROR: ', error);
        return from([]);
      }),
    );
  }

  // Filter dealers by country
  dealersByCountry(country: ICountry, region: IInnerRegion): any[] {
    const dealers = this.storeService.getData('commonReducer', 'dealers');

    return dealers.length > 0
      ? [{ id: -1, name: 'N/A', code: '', location: null }].concat(
          region.code
            ? dealers.filter(
                (dealer: IDealer) =>
                  dealer.location &&
                  country &&
                  dealer.location.region.code === region.code,
              )
            : dealers.filter(
                (dealer: IDealer) =>
                  dealer.location &&
                  country &&
                  dealer.location.country.isoCode === country.isoCode,
              ),
        )
      : [{ id: -1, name: 'N/A', code: '', location: null }];
  }
}
