import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
} from '@angular/core';
import { SearchService } from '../../services/search.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { faBlog, faBars } from '@fortawesome/free-solid-svg-icons';
import { StoreService } from '../../services/store.service';
import {
  PersistenceService,
  REQUESTS_STORE_GET,
} from '../../services/persistence.service';
import { NetworkStates } from '../../const/networkStates';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  constructor(
    private searchService: SearchService,
    private router: Router,
    private _eref: ElementRef,
    private storeService: StoreService,
    private readonly persistenceService: PersistenceService,
  ) {
    this.searchStateSubscription = this.searchService.searchState$.subscribe(
      (searchState: boolean) => {
        this.searchState = searchState;
      },
    );

    this.showSearch =
      window.location.href.indexOf('/training') >= 0 &&
      !(window.location.href.indexOf('/training/new') >= 0) &&
      !(window.location.href.indexOf('/training/edit') >= 0);

    this.cookiesSubscription = this.storeService
      .getDataSubscription('commonReducer', 'idb_connected')
      .subscribe((isConnected: boolean) => {
        if (isConnected) {
          this.persistenceService
            .get(REQUESTS_STORE_GET, 'profile/GET')
            .then((profile) => {
              if (profile) {
                const user =
                  typeof profile.data === 'string'
                    ? JSON.parse(profile.data)
                    : profile.data;
                this.persistenceService
                  .get(REQUESTS_STORE_GET, `${user.email}/cookies/GET`)
                  .then((cookies) => {
                    this.cookiesAccepted =
                      !!cookies && JSON.parse(cookies.data);
                  });
              }
            });
        }
      });

    this.offlineModeSubscribtion = this.storeService
      .getDataSubscription('utilsReducer', 'networkState')
      .subscribe((status: NetworkStates) => {
        this.disabled = status === NetworkStates.OFFLINE;
      });
  }
  searchState: boolean;
  showSearch: boolean;
  faBlog = faBlog;
  faBars = faBars;
  cookiesAccepted = true;
  disabled = false;

  searchStateSubscription: Subscription;
  cookiesSubscription: Subscription;
  routerEventsSubscription: Subscription;
  offlineModeSubscribtion: Subscription;

  @Output() hideWb: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:scroll', ['$event'])
  @HostListener('document:click', ['$event'])
  onClose(event) {
    this.close(event);
  }

  toggleSearch($event) {
    $event.preventDefault();

    this.searchService.toggleSearchBlock(!this.searchState);
    document.querySelector('.mobile-wrapper')?.classList.remove('mobile-show');
  }

  toHome() {
    const role = this.storeService.getData('profileReducer').role;
    this.router.navigate(['/notes']);
  }

  hideWbNav() {
    this.hideWb.emit();
  }

  close(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      document
        .querySelector('.mobile-wrapper')
        ?.classList.remove('mobile-show');
    }
  }

  ngOnDestroy() {
    if (this.searchStateSubscription) {
      this.searchStateSubscription.unsubscribe();
    }
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
    if (this.cookiesSubscription) {
      this.cookiesSubscription.unsubscribe();
    }
    if (this.offlineModeSubscribtion) {
      this.offlineModeSubscribtion.unsubscribe();
    }
  }
}
