import { Component, Input } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  PersistenceService,
  REQUESTS_STORE_GET,
} from '../../services/persistence.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookiesPolicy.component.html',
  styleUrls: ['./cookiesPolicy.component.scss'],
})
export class CookiesPolicyComponent {
  @Input() isOpen: boolean;
  descriptionIsOpened = false;
  faTimes = faTimes;

  constructor(private readonly persistenceService: PersistenceService) {}

  storeAgree() {
    this.persistenceService
      .get(REQUESTS_STORE_GET, 'profile/GET')
      .then((profile) => {
        if (profile) {
          const user = profile.data;
          this.persistenceService.save(REQUESTS_STORE_GET, {
            url: `${user.email}/cookies/GET`,
            data: 'true',
          });
        }
        this.isOpen = false;
      });
  }

  openDescription() {
    this.descriptionIsOpened = true;
  }

  closePopup($event) {
    this.descriptionIsOpened = $event;
  }
}
