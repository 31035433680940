import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
} from '@angular/core';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-select2',
  templateUrl: './customSelect2.component.html',
  styleUrls: ['./customSelect2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomSelect2Component),
    },
  ],
})
export class CustomSelect2Component implements ControlValueAccessor {
  constructor(private _eref: ElementRef) {}
  isOpen = false;
  arrowDown = faSortDown;
  arrowUp = faSortUp;
  current = {};

  @Input() height: number;
  @Input() maxItems: number;
  @Input() data: any[];
  @Input() titleOption = 'title';
  @Input() placeholder = '';
  @Input() disabled = false;

  @HostListener('document:click')
  onClose(event) {
    this.close(event);
  }

  toggleDropdown($event) {
    if (this.disabled) {
      return;
    }

    this.isOpen = !this.isOpen;
  }

  openDropdown($event) {
    $event.stopImmediatePropagation();
    if (this.disabled) {
      return;
    }
    this.isOpen = true;
  }

  closeDropdown($event) {
    this.isOpen = false;
  }

  close(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      // or some similar check
      this.closeDropdown(null);
    }
  }

  selectOption(value) {
    this.current = value;
    this.onChange(value);
    this.isOpen = false;
  }

  // Control value accessor
  onChange = (value: string) => {};
  onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    this.current = value;
  }
}
