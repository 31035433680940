import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { IProfile } from '../interfaces/IProfile';
import { catchError, map } from 'rxjs/operators';
import { RequestService } from './request.service';
import { TokenService } from './token.service';
import * as ProfileActions from '../actions/profileActions';
import { Store } from '@ngrx/store';

@Injectable()
export class ProfileService {
  constructor(
    private request: RequestService,
    private tokenService: TokenService,
    private store: Store,
  ) {}
  /**
   * Fetch profile by token
   */
  fetchProfile(): Observable<IProfile> {
    return this.request
      .get(
        'PROFILE',
        {},
        { Authorization: `Bearer ${this.tokenService.getToken()}` },
      )
      .pipe(
        map((profile: IProfile) => {
          this.store.dispatch(
            ProfileActions.store_user_profile_action({ user: profile }),
          );

          return profile;
        }),
        catchError((error: any) => {
          console.log('Fetch profile error: ', error);
          return throwError(error);
        }),
      );
  }
}
