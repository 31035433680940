import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { Roles } from '../../const/roles';

@Component({
  selector: 'app-preview-card',
  templateUrl: './previewCard.component.html',
  styleUrls: ['./previewCard.component.scss'],
})
export class PreviewCardComponent {
  faArrowDown = faArrowDown;
  Roles = Roles;

  @Input() item: any; // Short model of Training/Survey/Workbook
  @Input() status: any;
  @Input() entity = '';
  @Input() menu: any[] = this.initMenu;
  @Input() role: Roles = Roles.USER;

  @Output() refreshList: EventEmitter<boolean> = new EventEmitter<boolean>();

  get menuIcon() {
    return '';
  }

  get code(): string {
    return this.item && this.item.code
      ? `${this.item.code.substring(0, 3)} ${this.item.code.substring(
        3,
        this.item.code.length,
      )}`
      : '';
  }

  get codeBackground() {
    return '#003468';
  }

  get cardClasses() {
    return `row card ${this.entity}`;
  }

  get dateTitle(): string {
    return 'global.components.previewCard.createdDate';
  }

  get initMenu(): any[] {
    return [];
  }

  get createdMenu(): any[] {
    return this.menu?.length ? this.menu : this.initMenu;
  }

  get isManager() {
    return this.role === Roles.NATIONAL_MANAGER || this.role === Roles.LOCAL_MANAGER;
  }

  getCreatedDate(date: string): string {
    return moment(date).format('YYYY-MM-DD');
  }
}
