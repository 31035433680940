import { Component, EventEmitter, Output } from '@angular/core';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notes-alert',
  templateUrl: './unsavedAlert.component.html',
  styleUrls: ['./unsavedAlert.component.scss'],
})
export class UnsavedAlertComponent {
  @Output() confirmation: EventEmitter<boolean> = new EventEmitter();

  faSave = faSave;
  faClose = faTimes;

  confirm(value: boolean) {
    this.confirmation.emit(value);
  }
}
