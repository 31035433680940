import { Component, OnDestroy, OnInit } from '@angular/core';
import { Message } from '../../interfaces/message';
import { NotificationService } from '../../services/notification.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})

export class MessageComponent implements OnInit, OnDestroy {

  notificationsToShow: Message[] = [];
  notificationsStorge: Message[] = [];
  addInterval;
  removeInterval;
  intervalIsRunning = false;
  faTimes = faTimes;

  messageSubscription: Subscription;

  constructor(public service: NotificationService) {
    this.messageSubscription = this.service.$newError.subscribe((res: Message) => {
      this.addToStorage(res);
      this.intervalFunction('start');
    });
  }

  ngOnInit() { }

  intervalFunction(action: string) {
    if (action === 'start' && !this.intervalIsRunning) {
      this.intervalIsRunning = true;

      this.addInterval = setInterval(
        () => {
          this.removeToShow();
          if (this.notificationsToShow.length === 0 && this.notificationsStorge.length === 0) {
            this.intervalFunction('stop');
          }
        },
        4000);

      this.removeInterval = setInterval(() => { this.addToShow(); }, 1000);
    }

    if (action === 'stop') {
      this.intervalIsRunning = false;
      clearInterval(this.addInterval);
      clearInterval(this.removeInterval);
    }
  }

  addToStorage(message: Message) {
    if (message && this.notificationsStorge.filter((notif: Message) => notif.message === message.message).length === 0) {
      this.notificationsStorge.unshift(message);
    }
  }

  addToShow() {
    if (this.notificationsStorge.length > 0 && this.notificationsToShow.length < 4) {
      this.notificationsToShow.push(this.notificationsStorge.pop());
    }
  }

  removeToShow() {
    if (this.notificationsToShow.length > 0) {
      this.notificationsToShow.shift();
    }
  }

  closeNotificaton(message: Message) {
    this.notificationsToShow.splice(this.notificationsToShow.indexOf(message), 1);
    this.intervalFunction('start');
  }

  onMouse() {
    this.intervalFunction('stop');
  }

  mouseOut() {
    this.intervalFunction('start');
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }
}
