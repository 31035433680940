import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/authorization/authorization.module').then(
        (m) => m.AuthorizationModule,
      ),
  },
  {
    path: 'notes',
    loadChildren: () =>
      import('./modules/notes/notes.module').then((m) => m.NotesModule),
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./modules/templates/templates.module').then(
        (m) => m.TemplatesModule,
      ),
  },
  {
    path: 'shared',
    loadChildren: () =>
      import('./modules/shared/user.module').then((m) => m.UserModule),
  },
  {
    path: 'usermanagement',
    loadChildren: () =>
      import('./modules/userManagement/userManagement.module').then(
        (m) => m.UserManagementModule,
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth/samllogin',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' } as any),
    TranslateModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
