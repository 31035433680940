<div class="dropdown">
  <button class="btn btn-dropdown"
          type="button" aria-haspopup="true"
          [aria-expanded]="isOpen"
          [ngClass]="{'active-btn': isOpen}"
          (click)="toggleMenu($event)"
  >
    <fa-icon [icon]="faEllipsisV" *ngIf="!customIcon"></fa-icon>
    <img [src]="customIcon" alt="icon" *ngIf="customIcon">
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" *ngIf="isOpen">
        <div class="dropdown-item" *ngFor="let item of items" (click)="handleMenuItemClick($event, item)">
          <img src="../../../../assets/images/icons/card-menu/{{item.icon}}.svg" alt="icon">
          {{ item.title | translate }}
        </div>
  </div>
</div>
