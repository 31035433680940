import { BehaviorSubject } from 'rxjs';

export class WideImgService {
  private source: BehaviorSubject<object> = new BehaviorSubject({});
  $wideImgProps = this.source.asObservable();

  openImg(imageProps: { showed: boolean; link: string }): void {
    this.source.next(imageProps);
  }

  closeImg(): void {
    this.source.next({});
  }
}
