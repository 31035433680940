<ng-container *ngIf="notificationsToShow.length > 0">
  <ul>
    <li class="notification animateAdd" [ngClass]="message.type" (mouseover)="onMouse()" (mouseout)="mouseOut()" *ngFor="let message of notificationsToShow">
      <div class="content">
        <fa-icon (click)="closeNotificaton(message)" [icon]="faTimes" class="material-icons"></fa-icon>
      </div>
      <span>
        {{message.message}}
      </span>
    </li>
  </ul>
</ng-container>
