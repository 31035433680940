import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: 'textarea.component.html',
  styleUrls: ['textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextareaComponent),
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor {
  value = '';

  @Input() errorMessage = '';
  @Input() control: FormControl;
  @Input() placeholder = '';

  get showError(): boolean {
    return (
      ((this.control.touched || this.control.dirty) &&
        (this.control.hasError('minlength') ||
          this.control.hasError('required') ||
          this.control.hasError('maxlength'))) ||
      !!this.errorMessage
    );
  }

  onChange(value: string) {
    this.value = value;
  }

  onTouch() {}

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  writeValue(value: string) {
    this.value = value;
  }

  handleInput() {
    this.onChange(this.value);
  }
}
